import { useContext, useMemo } from "react";
import AppContext from "../../AppContext";
import { LuShoppingCart } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
// @ts-ignore
import logo from "../../assets/logo.svg";
// @ts-ignore
import burgas_logo from "../../assets/burgas_logo.png";
// @ts-ignore
import tatarskaya from "../../assets/tatarskaya.png";
import { orderAvailableBasedOnTime } from "../../utils";
import { transform } from "date-and-time";

const Header = () => {
    const { hotelId, roomId } = useParams();
    const { cart, orderInfo } = useContext(AppContext);

    const navigate = useNavigate();

    const onCartClick = () => {
        navigate(`/cart/${hotelId}/${roomId}`);
    }

    const onLogoClick = () => {
        navigate(`/${hotelId}/${roomId}`);
    }

    const totalQuantityInCart = cart.reduce((accum, cartItem) => accum + cartItem.currentQuantity, 0);
    console.log("CART : TOTAL  quantity", cart, totalQuantityInCart);

    // const totalPriceToDisplay = () => {
    //     let totalPrice = 0;

    //     cartFood?.forEach(cartItem => {
    //         const foodItem = foodData?.find(itm => itm.id === cartItem.foodId);

    //         totalPrice += !!foodItem ? foodItem.price * cartItem.foodQuantity : 0;
    //     })

    //     return totalPrice;
    // }


    const header_logo = useMemo(() => {
        if (hotelId === "3") {
            return burgas_logo;
        }

        if (hotelId === "5") {
            return tatarskaya;
        }

        return logo;
    }, [hotelId])

    const foreignLogo = useMemo(() => {
        return hotelId === "3" || hotelId === "5";
    }, [hotelId]);

    const burgasLogoStyle = {top: "0px", height: "100%", left: "0px"}
    const tatarskayaLogoStyle = {top: "50%", height: "25px", left: "0px", transform: "translateY(-50%)"}

    console.log("ORDER INFO", orderInfo);

    const getStyleBasedOnHotel = () => {
        if (hotelId === "3") return burgasLogoStyle;
        if (hotelId === "5") return tatarskayaLogoStyle;

        return {};
    }

    const getMadeOrderQuantity = (sets_data: {setId: number, quantity: number}[]): number => {
        return sets_data.reduce((accum, set) => accum + set.quantity, 0);
    }

    return (<div className="header">
        <div className="header-content">
            <div></div>
            <img className="header-logo" onClick={onLogoClick} src={header_logo} style={foreignLogo ? getStyleBasedOnHotel() : {}}/>

            {!orderAvailableBasedOnTime() ? (<div className="header-warning">Прием заказов доступен с 09:00 по 18:00</div>) : null}


            {orderInfo.length === 0 ? (
                <div onClick={onCartClick} className={`header-cart${totalQuantityInCart ? ' non-empty' : ''}`}>
                    <div className="cart-quantity">{!!totalQuantityInCart ? totalQuantityInCart : ""}</div>
                    <LuShoppingCart />
                </div>
            ) : (
                <div onClick={onCartClick} className={`header-cart${orderInfo.length !== 0 ? ' non-empty' : ''}`}>
                    <div className="cart-quantity">{getMadeOrderQuantity(JSON.parse(orderInfo[0].order_sets_data))}</div>
                    <LuShoppingCart />
                </div>
            )}

            {/* <div className="header-cart" onClick={onCartClick}> */}
                {/* <div className="header-cart-item"><svg className="header-cart-basket" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6h1.717L5.07 1.243a.5.5 0 0 1 .686-.172zM3.394 15l-1.48-6h-.97l1.525 6.426a.75.75 0 0 0 .729.574h9.606a.75.75 0 0 0 .73-.574L15.056 9h-.972l-1.479 6h-9.21z"></path></svg></div> */}
                {/* <div className="header-cart-item">{totalPriceToDisplay()} ₽</div> */}
            {/* </div> */}
        </div>
    </div>)
}

export default Header;