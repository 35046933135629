import "./n-set-card.css";
import { useContext, useEffect, useMemo, useState } from "react";
import { ISetDataResponse } from "../../../types/ISetDataResponse"
import { IDishesDataResponse } from "../../../types/IDishesDataResponse"
import { API_URL } from "../../../API_URL";
import { N_Accum_Button } from "../n_accum_button/N_Accum_Button";

import { FaWeight } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa6";
import { N_Main_Action_Btn } from "../n_main_action_btn/N_Main_Action_Btn";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../AppContext";
import { ICartItem } from "../../../types/ICartIitem";
import { orderAvailableBasedOnTime } from "../../../utils";

type Props = {
    set?: ISetDataResponse,
    dishes?: IDishesDataResponse[]
}

export const N_Set_Card = ({ set, dishes }: Props) => {
    const {hotelId, roomId} = useParams();
    
    const { addToCart, decreaseSetAmountInCart, cart, reservationData, orderInfo } = useContext(AppContext);
    const [mobile, setMobile] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth < 650) {
            setMobile(true);
        }
    }, []);

    const navigateToSet = () => {
        navigate(`/${hotelId}/${roomId}/${set?.set_id}`)
    }


    const quantity = useMemo(() => {
        return cart.find(cartItem => cartItem.setOrdered.set_id === set?.set_id)?.currentQuantity || 0;
    }, [cart]);

    const addToCartCb = () => {
        if (quantity === 0) {
            addToCart({currentQuantity: 1, setOrdered: set!!});
        }
        navigate(`/cart/${hotelId}/${roomId}`);
    }


    const increaseFoodQuantity = () => {
        if (quantity === 0) {
            addToCart({currentQuantity: 1, setOrdered: set!!});
        } else {
            addToCart({ currentQuantity: quantity + 1, setOrdered: set!! });
        }
    }

    const decreaseFoodQuantity = () => {
        decreaseSetAmountInCart(set?.set_id!!);
    }

    const setsInTotalInCart = (): number => {
        return cart.reduce((accum, cartItem) => accum + cartItem.currentQuantity, 0);
    }

    const imageLink = mobile ? `${set?.image_link.split(".")[0]}_rotated.jpg` : set?.image_link;

    return <div className={`dish-card${mobile ? " mobile": ""}`}>
        <div className="dish-image">
            <img onClick={navigateToSet} src={API_URL + `/${imageLink}`} alt={set?.title}/>
        </div>
        <div className="dish-desc">
            <div className="set-title">{set?.title}</div>
            {dishes?.map((dish, idx) => <DishDescription num={idx + 1} dish={dish} key={idx} />)}
        </div>
        <div className={`control-buttons${mobile ? " mobile": ""}`}>
            <div className="control-left">
                <N_Accum_Button 
                    quantity={quantity} 
                    increaseQuantity={increaseFoodQuantity}
                    decreaseQuantity={decreaseFoodQuantity}
                    maxReached={setsInTotalInCart() >= reservationData?.guests_num!!}
                />
            </div>

            <div className="control-right">
                <N_Main_Action_Btn disabled={!orderAvailableBasedOnTime() || orderInfo.length !== 0} title={"Выбрать"} onClickCb={() => increaseFoodQuantity()}/>

                <div onClick={navigateToSet} className="dish-details">Подробнее...</div>
            </div>
        </div>
    </div>
}

const DishDescription = ({dish, num}: {dish: IDishesDataResponse, num: number}) => {
    return (
        <div className="dish-item">

            <div className="dish-title">
                <span className="fredericka-the-great-regular">{num}.</span>
                <span className="title roboto-condensed-title-h2">{dish.title}</span>
            </div>
            <div className="dish-compound roboto-condensed-highlight-h3">
               <span>Состав: </span> {dish.compound}
            </div>
        </div>
    )
}