export const orderAvailableBasedOnTime = () => {
    const hours = new Date().getHours();

    if (hours >= 18 || hours < 9) {
        return false;
    }

    return true;
}

export const MORNING_ORDER_HOUR_START = 9;