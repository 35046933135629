import "./n-main-action-btn.css";

type Props = {
    onClickCb: () => void;
    title: string;
    isLoading: boolean;
}

export const N_Order_Action_Btn = ({ onClickCb, title, isLoading = false }: Props) => {

    if (isLoading) {
        return <div className="main-action-btn order">
            <span className="order-loader"></span>
        </div>
    }

    return <div onClick={onClickCb} className="main-action-btn order">{title}</div>
}