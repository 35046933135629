import { useContext, useEffect } from "react";
import AppContext from "../../AppContext";
import { useNavigate, useParams } from "react-router-dom";

import { Popup } from "../ui_elements/Popup";
import { AuthPage } from "./AuthPage";
import { N_Set_Card } from "../ui_elements/n_set_card/N_Set_Card";
import { ISetDataResponse } from "../../types/ISetDataResponse";
import { N_Cart_Mini_Widget } from "../ui_elements/n_cart_mini_widget/N_Cart_Mini_Widget";

const MainPage = () => {
    const { setsData, dishesData, error, noReservationError } = useContext(AppContext);
    const {hotelId, roomId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            console.log("Произошла ошибка");
            navigate("/")
        }
    }, [error]);

    useEffect(() => {
        localStorage.setItem("hotelId", hotelId!!);
        localStorage.setItem("roomId", roomId!!);
    }, [])

    const dishesBasedOnSetId = (set: ISetDataResponse) => {
        return dishesData.filter(dish => dish.set_id === set.set_id);
    }

    const renderSetItems = () => {
        return setsData.map((set,idx) => <N_Set_Card set={set} dishes={dishesBasedOnSetId(set)} key={idx} />)
    }

    if (noReservationError) {
        return (
            <div className="no-reservation-error">Мы не видим Вашу бронь. Возможно, Вы не включили завтрак в стоимость номера? Позвоните по телефону <a href="tel:+79782981818">+7-978-298-18-18</a></div>
        )
    } 

    return(
        setsData.length > 0 && dishesData.length > 0 ? (
            <>
        		<N_Cart_Mini_Widget />
                {renderSetItems()}
            </>
        ) : null
    )
}

export default MainPage;