import { useContext, useEffect, useRef, useState } from "react";
import "./n-cart-mini-widget.css";
import AppContext from "../../../AppContext";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { N_Main_Action_Btn } from "../n_main_action_btn/N_Main_Action_Btn";
import { useNavigate, useParams } from "react-router-dom";
export const N_Cart_Mini_Widget = () => {
    const navigate = useNavigate();
    const { hotelId, roomId } = useParams();
    const { reservationData, cart } = useContext(AppContext);

    const widgetVisible = useRef(false);
    const MAX_AVAILABLE = reservationData?.guests_num;

    const [isWidgetVisible, setIsWidgetVisible] = useState(false);

    useEffect(() => {
        const scrollListener = (e: any) => {
            if (window.scrollY >= 26 && !widgetVisible.current) {
                setIsWidgetVisible(true);
                widgetVisible.current = true;
            }

            if (window.scrollY < 26 && widgetVisible.current) {
                setIsWidgetVisible(false);
                widgetVisible.current = false;
            }
        }

        window.addEventListener("scroll", scrollListener);

        return () => {
            window.removeEventListener("scroll", scrollListener);
        }
    }, [])

    const amountOfChosen = (): number => {
        return cart.reduce((accum, cartItem) => cartItem.currentQuantity + accum, 0);
    }

    return <div className={`cart-mini-widget${!isWidgetVisible ? ' hidden' : ''}`}>
       {isWidgetVisible ? (
        <>
             <div className="order-info">
                Вы выбрали {amountOfChosen()} из {MAX_AVAILABLE} <span><MdOutlinePeopleAlt /></span> доступных Сетов
            </div>
            <div className="order-btn">
                <N_Main_Action_Btn onClickCb={() => {navigate(`/cart/${hotelId}/${roomId}`)}} title="В корзину"/>
            </div>
        </>
       ) : null}
    </div>
}