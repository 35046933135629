import { createContext } from "react";

import { ISetDataResponse } from "./types/ISetDataResponse";
import { IDishesDataResponse } from "./types/IDishesDataResponse";
import { IReservationResponse } from "./types/IReservationResponse";
import { ICartItem } from "./types/ICartIitem";
import { IOrderRequestData } from "./types/IOrderRequestData";
import { ICreateOrderStatus } from "./types/ICreateOrderStatus";
import { IOrderInfoResponse } from "./types/IOrderInfoResponse";

type AppContextType = {
    cart: ICartItem[];
    error: boolean;

    setsData: ISetDataResponse[];
    dishesData: IDishesDataResponse[];
    reservationData: IReservationResponse | null;
    noReservationError: boolean;

    addToCart: (cartItem: ICartItem) => void;
    decreaseSetAmountInCart: (setId: number) => void;
    makeOrder: (orderData: IOrderRequestData) => void;

    createOrderStatus: ICreateOrderStatus;

    clearCart: () => void;
    clearOrderStatus: () => void;

    orderInfo: IOrderInfoResponse[];

    getLatestOrderData: (hotelId: string, roomId: string) => void;
} 

export default createContext<AppContextType>({
    cart: [],
    error: false,

    setsData: [],
    dishesData: [],
    reservationData: null,
    noReservationError: false,

    addToCart: (cartItem: ICartItem) => {},
    decreaseSetAmountInCart: (setId: number) => {},
    makeOrder: (orderData: IOrderRequestData) => {},

    createOrderStatus: { isError: false ,isCreated: false, isLoading: false },

    clearCart: () => {},
    clearOrderStatus: () => {},

    orderInfo: [],

    getLatestOrderData: () => {}
});