import { useContext, useEffect } from "react";
import { N_Breadcrumb_Main_Btn } from "../ui_elements/n_breadcrumb_main_btn/N_Breadcrumb_Main_Btn";
import "./CartOrderStatus.css";
import { MdOutlineErrorOutline } from "react-icons/md";
import AppContext from "../../AppContext";

export const CartOrderError = () => {
    const { clearCart, clearOrderStatus } = useContext(AppContext);

    useEffect(() => {
        return () => {
            clearCart();
            clearOrderStatus();
        }
    }, [])


    return(
        <div className="cart-order-error-screen">
            <N_Breadcrumb_Main_Btn />
            <div className="error-content">
                <div className="error-cross">
                    <MdOutlineErrorOutline />

                    <span>Ошибка при создании заказа. Попробуйте позже, или свяжитесь с нами</span>
                    <span className="error-contact"><a href="tel:+79782981818">+7-978-298-18-18</a></span>
                </div>
            </div>
        </div>
    )
}