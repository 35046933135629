import { useContext, useEffect } from "react";
import { N_Breadcrumb_Main_Btn } from "../ui_elements/n_breadcrumb_main_btn/N_Breadcrumb_Main_Btn";
import "./CartOrderStatus.css";
import { FaCheck } from "react-icons/fa6";
import AppContext from "../../AppContext";

export const CartOrderSuccess = () => {
    const { clearCart, clearOrderStatus } = useContext(AppContext);

    useEffect(() => {
        return () => {
            clearCart();
            clearOrderStatus();
        }
    }, [])

    return(
        <div className="cart-order-success-screen">
            <N_Breadcrumb_Main_Btn />
            <div className="success-content">
                <div className="success-check">
                    <FaCheck />

                    <span>Ваш заказ успешно создан</span>
                </div>
            </div>
        </div>
    )
}