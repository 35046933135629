import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();
    const {hotelId, roomId} = useParams();

    // useEffect(() => {
    //     if (!Boolean(hotelId) || !Boolean(roomId)) {
    //         const storageHotelId = localStorage.getItem("hotelId");
    //         const storageRoomId = localStorage.getItem("roomId");

    //         navigate(`/${storageHotelId}/${storageRoomId}`);
    //     } else {
    //         localStorage.setItem("hotelId", hotelId || "");
    //         localStorage.setItem("hotelId", roomId || "");
    //     }
    // }, [])

   
    return(
        <div className="error-page">Перейдите по ссылке из меню или отсканируйте QR код</div>
    )
}

export default ErrorPage;