import './App.css';
import { useState, useEffect } from 'react';
import axios from "axios";
import { API_URL } from "./API_URL";
import AppContext from './AppContext';

import { ISetDataResponse } from "./types/ISetDataResponse";
import { IDishesDataResponse } from './types/IDishesDataResponse';

import { createBrowserRouter, Outlet, RouterProvider, useParams } from 'react-router-dom';

import MainPage from './components/Pages/MainPage';
import CartPage from './components/Pages/CartPage';
import { N_Details_Page } from './components/ui_elements/n_details_page/N_Details_Page';
import Header from './components/ui_elements/Header';
import ErrorPage from './components/Pages/ErrorPage';
import { AuthPage } from './components/Pages/AuthPage';
import { ICartItem } from './types/ICartIitem';
import { IReservationResponse } from './types/IReservationResponse';
import { N_Cart_Mini_Widget } from './components/ui_elements/n_cart_mini_widget/N_Cart_Mini_Widget';
import { IOrderRequestData } from './types/IOrderRequestData';
import { ICreateOrderStatus } from './types/ICreateOrderStatus';
import { MORNING_ORDER_HOUR_START, orderAvailableBasedOnTime } from './utils';
import { IOrderInfoResponse } from './types/IOrderInfoResponse';
const Layout = () => (
	<div className="roboto-condensed-regular">
		<Header />
		<div className="food-cards-wrapper">
			<Outlet />
		</div>
	</div>
)

let router = createBrowserRouter([
	{
		element: <Layout />,
		children: [
			{
				path: "/:hotelId/:roomId",
				Component() {
					return <MainPage />
				}
			},
			{
				path: "/cart/:hotelId/:roomId",
				Component() {
					return <CartPage />
				}
			},
			{
				path: "/:hotelId/:roomId/:setId",
				Component() {
					return <N_Details_Page />
				}
			},
			{
				path: "*",
				Component() {
					return <ErrorPage />
				}
			},
		]
	}
]);

function App() {
	const [cart, setCart] = useState<ICartItem[]>([]);
	const [reservationData, setReservationData] = useState<IReservationResponse | null>(null);

	const [error, setError] = useState(false);
	const [noReservationError, setNoReservationError] = useState(false);

	const [setsData, setSetsData] = useState<ISetDataResponse[]>([]);
	const [dishesData, setDishesData] = useState<IDishesDataResponse[]>([]);
	const [createOrderStatus, setCreateOrderStatus] = useState<ICreateOrderStatus>({ isError: false, isCreated: false, isLoading: false });

	const [roomHotelData, setRoomHotelData] = useState({ roomId: "", hotelId: "" })

	const [orderInfo, setOrderInfo] = useState<IOrderInfoResponse[]>([]);

	useEffect(() => {
		async function fetchSetsData() {
			const setData = await axios.get(API_URL + "/hotel_client/get_sets");
			setSetsData(setData.data);
		}

		try {
			fetchSetsData();
		} catch (error) {
			console.log("Не смогли получить сеты");
		}
	}, []);

	useEffect(() => {
		const pathname = window.location.pathname.split("/");

		const roomIdentifiers: string[] = [];


		for (let i = 0; i < pathname.length; i++) {
			if (!!Number(pathname[i])) {
				roomIdentifiers.push(pathname[i], pathname[i+1]);
				break;
			}
		}

		async function fetchReservationData() {
			if (roomIdentifiers.length !== 2) {
				console.log("Не указаны строковые параметры");
				setError(true);
			}

			setRoomHotelData({ roomId: roomIdentifiers[1], hotelId: roomIdentifiers[0]});


			const date = new Date();
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();

			const reservationData = await axios.get(API_URL + "/hotel_client/get_reservation" + `?hotelId=${roomIdentifiers[0]}&roomId=${roomIdentifiers[1]}&year=${year}&month=${month}&day=${day}`);

			// Берем последнюю резервацию
			if (reservationData.data.length === 0) {
				// throw new Error();
				console.log("Для этого номера нет резервации");
				setNoReservationError(true);
			}

			const lastReservation = reservationData.data[0];

			setReservationData(lastReservation);
		}

		try {
			fetchReservationData();
		} catch (error) {
			// setError(true);
			// console.error("Не смогли получить резервацию");
		}
	}, []);

	useEffect(() => {
		if (setsData.length) {
			async function fetchDishesData() {
				try {
					const dishesData = await axios.get(API_URL + "/hotel_client/get_dishes");
					setDishesData(dishesData.data);
					console.log(dishesData);
				} catch (error) {
					console.log("Не смогли получить информацию по блюдам");
					
				}
			}
			fetchDishesData();
		}
	}, [setsData]);

	useEffect(() => {
		getLatestOrderData(roomHotelData.hotelId, roomHotelData.roomId);
	}, [roomHotelData]);

	const getLatestOrderData = async (hotelId: string, roomId: string) => {
		if (!Boolean(hotelId) || !Boolean(roomId)) {
			return;
		}

		const currentHour = new Date().getHours()

		let datetimeFrom = "";
		let datetimeTo = "";

		if (currentHour < MORNING_ORDER_HOUR_START && currentHour >= 0) {
			// Берём прошлый день для dateFrom и сегодняшний для dateTo
			const dateFrom = new Date(new Date().valueOf() - 43200000 * 2).toISOString();
			const fromSplit = dateFrom.split("T");
			const dateFromResult = fromSplit[0] + "T" + "09:00:00";
			console.log("++!",dateFromResult)

			const dateTo = new Date(new Date().valueOf() + 1000 * 60 * 60 * 3).toISOString();
			const toSplit = dateTo.split("T");
			const dateToResult = toSplit[0] + "T" + "09:00:00";

			datetimeFrom = dateFromResult;
			datetimeTo = dateToResult
		} else if (currentHour >= MORNING_ORDER_HOUR_START) {
			// Берём сегодряшний день для dateFrom и завтрашний для dateTo

			const dateFrom = new Date().toISOString();
			const fromSplit = dateFrom.split("T");
			const dateFromResult = fromSplit[0] + "T" + "09:00:00";

			const dateTo = new Date(new Date().valueOf() + 43200000 * 2).toISOString();
			const toSplit = dateTo.split("T");
			const dateToResult = toSplit[0] + "T" + "09:00:00";

			datetimeFrom = dateFromResult;
			datetimeTo = dateToResult;
		}

		 try {
			const latestOrderData = await axios.post(API_URL + "/hotel_client/get_order_info", {
				roomId: roomId, 
				hotelId: hotelId,
				datetimeFrom: datetimeFrom,
				datetimeTo: datetimeTo
				}
			);
			setOrderInfo(latestOrderData.data);

		} catch (error) {
			console.log("ERROR ", error);
		}
	}

	const addToCart = (cartItem: ICartItem) => {
		if (!orderAvailableBasedOnTime() || orderInfo.length !== 0) return;
		
		// @ts-ignore
		const reservedToDateMinusDay = new Date(new Date(reservationData?.reserved_to) - 32400000);
		const currentDate = new Date();

		console.log("213", currentDate, reservedToDateMinusDay);

		if (currentDate > reservedToDateMinusDay) {
			return;
		}

		const setsInCart = cart.reduce((accum, cartItem) => accum + cartItem.currentQuantity, 0);
		if (reservationData?.guests_num === setsInCart) {
			return;
		}

		const setId = cartItem.setOrdered.set_id;
		const isSetInCart = cart.find(cartI => cartI.setOrdered.set_id === setId);

		if (!!isSetInCart) {
			const updatedCartItems = cart.map(cartI => {
				if (cartI.setOrdered.set_id === setId) {
					return { ...cartItem };
				}
				return cartI;
			})
			setCart(updatedCartItems);
		} else {
			const cartItems = cart?.map(i => i);
			cartItems?.push(cartItem);
	
			setCart(cartItems || []);
		}

	}

	const decreaseSetAmountInCart = (setId: number) => {
		const cartItemToDecrease = cart.find(cartItem => cartItem.setOrdered.set_id === setId);

		if (!cartItemToDecrease) return;

		const cartItemRemaining = cart.filter(cartItem => cartItem.setOrdered.set_id !== setId);

		if (!(cartItemToDecrease.currentQuantity <= 1)) {
			cartItemRemaining.push({ ...cartItemToDecrease, currentQuantity: cartItemToDecrease.currentQuantity - 1 });
		}

		setCart(cartItemRemaining);
	}

	const makeOrder = async (orderReqData: IOrderRequestData) => {
		try {
			setCreateOrderStatus({ ...createOrderStatus, isLoading: true });

			const orderRes = await axios.post(API_URL + "/hotel_client/create_order", {order: orderReqData});

			if (!orderRes.data.orderCreated) {
				throw new Error("Заказ не создан");
			}
			setCreateOrderStatus({ isError: false, isLoading: false, isCreated: true });


			await getLatestOrderData(roomHotelData.hotelId, roomHotelData.roomId);
		} catch (error) {
			console.log("ERROR DURING ORDER CREaTING", error);
			setCreateOrderStatus({ isCreated: false, isError: true, isLoading: false });
		} finally {
			// setCreateOrderStatus({ ...createOrderStatus, isLoading: false });
		}
	}

	const clearCart = () => {
		setCart([]);
	}

	const clearOrderStatus = () => {
		setCreateOrderStatus({ isError: false, isCreated: false, isLoading: false });
	}

	return (
		<AppContext.Provider value={{
			cart,
			error,

			setsData,
			dishesData,
			reservationData,

			addToCart,
			decreaseSetAmountInCart,

			noReservationError,
			makeOrder,
			
			createOrderStatus,

			clearCart,
			clearOrderStatus,

			orderInfo,

			getLatestOrderData
		}}>
			<RouterProvider router={router} />
		</AppContext.Provider>
	);
}

export default App;
