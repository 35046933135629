import "./n-main-action-btn.css";

type Props = {
    onClickCb: () => void;
    title: string;
    disabled?: boolean;
}

export const N_Main_Action_Btn = ({ onClickCb, title, disabled = false }: Props) => {
    return <div onClick={onClickCb} className={`main-action-btn${disabled ? " btn-disabled" : ""}`}>{title}</div>
}