import "./n-accum-btn.css";
import { memo } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";

type Props = {
    quantity: number;
    decreaseQuantity: () => void;
    increaseQuantity: () => void;
    maxReached: boolean;
}

export const N_Accum_Button = memo(({ quantity, maxReached, decreaseQuantity, increaseQuantity } : Props) => {
    const minReached = quantity <= 0;

    const onIncClick = () => {
        if (!maxReached) {
            increaseQuantity();
        }
    }

    const onDecClick = () => {
        if (!minReached) {
            decreaseQuantity();
        }
    }

    return <div className="accum-btn-wrapper">
        <div onClick={onDecClick} className={`accum-btn dec-button${minReached ? " disabled" : ""}`}><FaMinus /></div>
        <div className="accum-quantity">{quantity}</div>
        <div onClick={onIncClick} className={`accum-btn inc-button${maxReached ? " disabled" : ""}`}>
            <FaPlus />
        </div>
    </div>
});