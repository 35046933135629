import "./n-cart-set-item.css";
import { useContext, useEffect, useMemo, useState } from "react";
import { IDishesDataResponse } from "../../../types/IDishesDataResponse";
import { ISetDataResponse } from "../../../types/ISetDataResponse";
import { N_Set_Card } from "../n_set_card/N_Set_Card";
import AppContext from "../../../AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../API_URL";
import { N_Accum_Button } from "../n_accum_button/N_Accum_Button";
import { N_Main_Action_Btn } from "../n_main_action_btn/N_Main_Action_Btn";
import { ICartItem } from "../../../types/ICartIitem";

type Props = {
    set?: ISetDataResponse,
}

export const N_Cart_Set_Item = ({ set }: Props) => {
    const { dishesData, reservationData, addToCart, decreaseSetAmountInCart, cart } = useContext(AppContext);
    
    const dishes = useMemo(() => {
        return dishesData.filter(dish => dish.set_id === set?.set_id);
    }, [dishesData]);


    const {hotelId, roomId} = useParams();
    
    const [mobile, setMobile] = useState(false);
    const quantity = useMemo(() => {
        return cart.find(cartItem => cartItem.setOrdered.set_id === set?.set_id)?.currentQuantity || 0;
    }, [cart]);

    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth < 650) {
            setMobile(true);
        }
    }, []);

    const navigateToSet = () => {
        navigate(`/${hotelId}/${roomId}/${set?.set_id}`)
    }


    const imageLink = mobile ? `${set?.image_link.split(".")[0]}_rotated.jpg` : set?.image_link;

    const setsInTotalInCart = (): number => {
        return cart.reduce((accum, cartItem) => accum + cartItem.currentQuantity, 0);
    }

    const increaseFoodQuantity = () => {
        if (quantity === 0) {
            addToCart({currentQuantity: 1, setOrdered: set!!});
        } else {
            addToCart({ currentQuantity: quantity + 1, setOrdered: set!! });
        }
    }

    const decreaseFoodQuantity = () => {
        decreaseSetAmountInCart(set?.set_id!!);
    }


    return <div className={`dish-card-cart${mobile ? " mobile": ""}`}>
        <div className="dish-card-info">
            <div className="dish-image">
                <img onClick={navigateToSet} src={API_URL + `/${imageLink}`} alt={set?.title}/>
            </div>
            <div className="dish-desc">
                <div className="set-title">{set?.title}</div>
                {dishes?.map((dish, idx) => <DishDescription num={idx + 1} dish={dish} key={idx} />)}
            </div>
        </div>
        <div className={`control-buttons${mobile ? " mobile": ""}`}>
            <div className="control-left">
                <N_Accum_Button 
                    quantity={quantity}
                    // setQuantity={setQuantity} 
                    increaseQuantity={increaseFoodQuantity}
                    decreaseQuantity={decreaseFoodQuantity}
                    maxReached={setsInTotalInCart() >= reservationData?.guests_num!!}/>
            </div>

            <div className="control-right">
                <div onClick={navigateToSet} className="dish-details">Подробнее...</div>
            </div>
        </div>
    </div>
}

const DishDescription = ({dish, num}: {dish: IDishesDataResponse, num: number}) => {
    return (
        <div className="dish-item">

            <div className="dish-title">
                <span className="fredericka-the-great-regular">{num}.</span>
                <span className="title roboto-condensed-title-h3">{dish.title}</span>
            </div>
        </div>
    )
}