// Просто дублирует всё, что есть в N_Set_Card . Лень думать над тем, как сделать красиво. Хорошего кода ( нет ) должно быть много (: 
import "./n-set-card.css";
import "./n-set-card.css";
import { useContext, useEffect, useMemo, useState } from "react";
import { ISetDataResponse } from "../../../types/ISetDataResponse"
import { IDishesDataResponse } from "../../../types/IDishesDataResponse"
import { API_URL } from "../../../API_URL";
import { N_Accum_Button } from "../n_accum_button/N_Accum_Button";

import { FaWeight } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa6";
import { N_Main_Action_Btn } from "../n_main_action_btn/N_Main_Action_Btn";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../AppContext";
import { ICartItem } from "../../../types/ICartIitem";
import { orderAvailableBasedOnTime } from "../../../utils";

type Props = {
    set?: ISetDataResponse,
    dishes?: IDishesDataResponse[]
}

export const N_SetCard_Extended = ({ set, dishes }: Props) => {
    const { addToCart, reservationData, decreaseSetAmountInCart, cart, orderInfo } = useContext(AppContext);

    const {hotelId, roomId} = useParams();
    const navigate = useNavigate();

    const [mobile, setMobile] = useState(false);
    const quantity = useMemo(() => {
        return cart.find(cartItem => cartItem.setOrdered.set_id === set?.set_id)?.currentQuantity || 0;
    }, [cart]);

    const decreaseSetDataInCart = () => {
        const setInCart = cart.find(cartI => cartI.setOrdered.set_id === set?.set_id) as ICartItem;
        if (!setInCart) {
            return;
        }

        if (quantity < setInCart.currentQuantity) {
            console.log("###CART", quantity, setInCart);
        }
    }

    useEffect(() => {
        if (window.innerWidth < 650) {
            setMobile(true);
        }
    }, []);

    const setsInTotalInCart = (): number => {
        return cart.reduce((accum, cartItem) => accum + cartItem.currentQuantity, 0);
    }


    const imageLink = `${set?.image_link.split(".")[0]}_rotated.jpg`;

    const increaseFoodQuantity = () => {
        if (quantity === 0) {
            addToCart({currentQuantity: 1, setOrdered: set!!});
        } else {
            addToCart({ currentQuantity: quantity + 1, setOrdered: set!! });
        }
    }

    const decreaseFoodQuantity = () => {
        decreaseSetAmountInCart(set?.set_id!!);
    }

    const addToCartCb = () => {
        if (quantity === 0) {
            addToCart({currentQuantity: 1, setOrdered: set!!});
        }
        navigate(`/cart/${hotelId}/${roomId}`);
    }

    return <div className={`card-extended dish-card mobile`}>
        <div className="set-title">{set?.title}</div>
        <div className="dish-image">
            <img src={API_URL + `/${imageLink}`} alt={set?.title}/>
        </div>
        <div className="dish-desc">
            {dishes?.map((dish, idx) => <DishDescription num={idx + 1} dish={dish} key={idx} />)}
        </div>
        <div className={`control-buttons mobile`}>
            <div className="control-left">
                <N_Accum_Button 
                    quantity={quantity} 
                    // setQuantity={setQuantity} 
                    increaseQuantity={increaseFoodQuantity}
                    decreaseQuantity={decreaseFoodQuantity}
                    maxReached={setsInTotalInCart() >= reservationData?.guests_num!!}/>
            </div>

            <div className="control-right">
                <N_Main_Action_Btn disabled={!orderAvailableBasedOnTime() || orderInfo.length !== 0} title={"Выбрать"} onClickCb={() => increaseFoodQuantity()}/>
            </div>
        </div>
    </div>
}

const DishDescription = ({dish, num}: {dish: IDishesDataResponse, num: number}) => {
    return (
        <div className="dish-item">
            <div className="dish-title">
                <span className="fredericka-the-great-regular">{num}.</span>
                <span className="title roboto-condensed-title-h2">{dish.title}</span>
            </div>

            <div className="dish-item-desc">{dish.description}</div>

            <div className="dish-compound roboto-condensed-highlight-h3">
               <span>Состав: </span> {dish.compound}
            </div>

            <div className="dish-compound roboto-condensed-highlight-h3">
               <span>Аллергены: </span> {dish.allergens}
            </div>

            <div className="dish-meta roboto-condensed-highlight-h3">
                <div className="dish-meta-item">
                    <FaWeight />
                    <span>{dish.weight}</span>
                </div>
                <div className="dish-meta-item">
                    <FaChartBar />
                    <span>{dish.callories}</span>
                </div>
            </div>
        </div>
    )
}