import "./n-cart-set-item.css";
import { useContext, useEffect, useMemo, useState } from "react";
import { IDishesDataResponse } from "../../../types/IDishesDataResponse";
import { ISetDataResponse } from "../../../types/ISetDataResponse";
import AppContext from "../../../AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../API_URL";

type Props = {
    set?: ISetDataResponse,
    quantity?: number
}

export const N_Cart_Set_Ordered_Item = ({ set, quantity }: Props) => {
    const { dishesData, reservationData, addToCart, decreaseSetAmountInCart, cart } = useContext(AppContext);
    
    const dishes = useMemo(() => {
        return dishesData.filter(dish => dish.set_id === set?.set_id);
    }, [dishesData]);


    const {hotelId, roomId} = useParams();
    const [mobile, setMobile] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth < 650) {
            setMobile(true);
        }
    }, []);

    const navigateToSet = () => {
        navigate(`/${hotelId}/${roomId}/${set?.set_id}`)
    }


    const imageLink = mobile ? `${set?.image_link.split(".")[0]}_rotated.jpg` : set?.image_link;

    return <div className={`dish-card-cart${mobile ? " mobile": ""}`}>
        <div className="dish-card-info">
            <div className="dish-image">
                <img onClick={navigateToSet} src={API_URL + `/${imageLink}`} alt={set?.title}/>
            </div>
            <div className="dish-desc">
                <div className="set-title">{set?.title}</div>
                {dishes?.map((dish, idx) => <DishDescription num={idx + 1} dish={dish} key={idx} />)}
            </div>
        </div>
        <div className={`control-buttons${mobile ? " mobile": ""}`}>
            <div className="order-info-quantity">{quantity}</div>

        </div>
    </div>
}

const DishDescription = ({dish, num}: {dish: IDishesDataResponse, num: number}) => {
    return (
        <div className="dish-item">

            <div className="dish-title">
                <span className="fredericka-the-great-regular">{num}.</span>
                <span className="title roboto-condensed-title-h3">{dish.title}</span>
            </div>
        </div>
    )
}