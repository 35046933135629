import { FaArrowLeft } from "react-icons/fa6";
import "./breadcrumb_btn.css";
import { useNavigate, useParams } from "react-router-dom";

export const N_Breadcrumb_Main_Btn = () => {
    const {hotelId, roomId} = useParams();
    const navigate = useNavigate();

    const onNavigateToMain = () => {
        navigate(`/${hotelId}/${roomId}`);
    }

    return(
        <div onClick={onNavigateToMain} className="breadcrumb-btn">
            <FaArrowLeft />
            <span>На главную</span>
        </div>
    )
}