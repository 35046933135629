import { useContext } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../AppContext";
import { N_SetCard_Extended } from "../n_set_card/N_Set_Card_Extended";


export const N_Details_Page = () => {
    const {hotelId, roomId, setId} = useParams();
    const { setsData, dishesData } = useContext(AppContext);

    const set = setsData.find(set => set.set_id === (Number(setId) || -1));
    const dishes = dishesData.filter(dish => dish.set_id === (Number(setId) || -1));

    console.log("#??", set, dishes, setId);

    if ((!(!!set) || dishes.length === 0)) {
        return null;
    }

    return <div className="details-page">
        <N_SetCard_Extended set={set} dishes={dishes} />
    </div>;
}