import "./Cart.css";
import { useContext, useMemo, ReactNode, useRef, useState, useEffect } from "react";
import AppContext from "../../AppContext";

import { OrderCartItem } from "./OrderCartItem";
import { IOrderedCartItemData } from "../../types/IOrderCartItemData";

import { PaymentComponent } from "./PaymentComponent";
import { N_Cart_Set_Item } from "../ui_elements/n_cart_set_item/N_Cart_Set_item";
import { N_Main_Action_Btn } from "../ui_elements/n_main_action_btn/N_Main_Action_Btn";
import { N_Order_Action_Btn } from "../ui_elements/n_main_action_btn/N_Order_Action_Btn";
import { useParams } from "react-router-dom";
import { CartOrderSuccess } from "./CartOrderSuccess";
import { CartOrderError } from "./CartOrderError";
import { N_Cart_Set_Ordered_Item } from "../ui_elements/n_cart_set_item/N_Cart_Set_Ordered_Item";
import { AiOutlineClose } from "react-icons/ai";

export const Cart = () => {
    const { cart, makeOrder, createOrderStatus, orderInfo, setsData, reservationData } = useContext(AppContext);
    const {hotelId, roomId} = useParams();

    const footnoteRef = useRef<string>();

    const [addSetsWarning, setAddSetsWarning] = useState(false);

    console.log("create order status", orderInfo);

    const createOrderError = createOrderStatus.isError;
    const createOrderSuccess = createOrderStatus.isCreated;

    useEffect(() => {
        setAddSetsWarning(false);
    }, [cart]);

    useEffect(() => {
        return () => {
            setAddSetsWarning(false);
        }
    }, [])

    const onCreateOrder = () => {
        const totalOrderedQuantity = cart.reduce((accum, value) => accum + value.currentQuantity, 0);

        if (totalOrderedQuantity < reservationData?.guests_num!!) {
            setAddSetsWarning(true);
            return
        }

        const cartItems = cart.map(cartItem => {
            return { setId: cartItem.setOrdered.set_id, quantity: cartItem.currentQuantity }
        })

        makeOrder({
            orderPayload: cartItems,
            note: footnoteRef.current || "",
            hotelId: hotelId!!,
            roomId: roomId!!
        });
    }

    if (createOrderError) {
        return <CartOrderError />
    }

    if (createOrderSuccess) {
        return <CartOrderSuccess />
    }


    if (orderInfo.length > 0) {
        const orderedSetsData = JSON.parse(orderInfo[0].order_sets_data);
        const dataToDisplay = []

        for (let orderedSet of orderedSetsData) {
            const setId = orderedSet.setId;

            const set = setsData.find(s => s.set_id === setId);
            dataToDisplay.push({ set, quantity: orderedSet.quantity });
        }

        return <div className="cart-content-wrapper">
            <div className="cart-notice-container">
                <div className="order-wish">Ваш заказ</div>
            </div>
            <div className="cart-dish-item">
                {dataToDisplay.map((d, idx) => {
                    return <N_Cart_Set_Ordered_Item key={idx} set={d.set} quantity={d.quantity}/>
                })}
            </div>
            
        </div>
    }


    return(
        cart.length === 0 ? <EmptyCart /> : (
            <div className="cart-content-wrapper">
                <div className="cart-dish-item">
                    {cart.map((cartItem, idx) => {
                        return <N_Cart_Set_Item key={idx} set={cartItem.setOrdered}/>
                    })}
                </div>
                <div className="cart-notice-container">
                    <div className="order-wish">Ваши пожелания к заказу:</div>
                    <NoticeTextField footnoteRef={footnoteRef} />
                </div>
                <div className="order-btn-container">
                    {addSetsWarning ? (
                        <div className="not-enough-sets-ordered">
                            <span>
                                Вы оплатили завтраки на {reservationData?.guests_num} человек. Но заказали только {cart.reduce((accum, value) => accum + value.currentQuantity, 0)}.
                                Пожалуйста, дополните Ваш заказ.
                            </span>
                            <div onClick={() => setAddSetsWarning(false)} className="not-enough-sets-close">
                                <AiOutlineClose />
                            </div>

                        </div>
                    ) : null}
                    <OrderBtn onCreateOrder={onCreateOrder} isLoading={createOrderStatus.isLoading} />
                </div>
            </div>
        )
    )
}

const NoticeTextField = (props: {footnoteRef: React.MutableRefObject<string | undefined>}) => {
    const onInputChange = (e: any) => {
        props.footnoteRef.current = e.target.value;        
    }

    return <textarea onChange={onInputChange} spellCheck="false" className="cart-notice"></textarea>
}

const OrderBtn = (props: {onCreateOrder: any, isLoading: boolean}) => {
    return <N_Order_Action_Btn onClickCb={props.onCreateOrder} title="Заказать" isLoading={props.isLoading} />;
}

const OrderLoader = () => {
    return <span className="order-loader"></span>;
}


const EmptyCart = () => {
    return <div className="empty-cart">Вы ещё ничего не заказали</div>
}